body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cs-body {
  margin-top: 60px;
}

.cs-primary-bg {
  background-color: #d32f2f;
}

.cs-login {
  min-height: 100vh;
}

.cs-login img {
  max-width: 25vh;
}

.cs-login #title {
  text-align: center;
  padding-top: 5vh;
}

.cs-button {
  background-color: #d32f2f;
  border-width: 2px;
}

.navbar-brand img {
  max-width: 2.3rem;
}

.cs-error {
  padding: 8px;
  background-color: #d32f2f;
  text-align: center;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: bold;
}

.cs-error-margin {
  margin: 10px;
}

.cs-empty {
  text-align: center;
  padding: 10px;
}

.cs-pointer {
  cursor: pointer;
}

.cs-botones-estado {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  color: white;
}

.cs-color-todos {
  background-color: #3498db;
}

.cs-color-retiro {
  background-color: #795548;
}

.cs-color-domicilio {
  background-color: #8e44ad;
}

.cs-color-servir {
  background-color: #2c3e50;
}

.cs-selected {
  border-bottom: 5px solid #f44336;
}
